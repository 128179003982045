import request from '@/utils/request'


// 查询任务奖励用户记录列表
export function listTaskUser(query) {
  return request({
    url: '/user/task-users/list',
    method: 'get',
    params: query
  })
}

// 查询任务奖励用户记录分页
export function pageTaskUser(query) {
  return request({
    url: '/user/task-users/page',
    method: 'get',
    params: query
  })
}

// 查询任务奖励用户记录详细
export function getTaskUser(data) {
  return request({
    url: '/user/task-users/detail',
    method: 'get',
    params: data
  })
}

// 新增任务奖励用户记录
export function addTaskUser(data) {
  return request({
    url: '/user/task-users/add',
    method: 'post',
    data: data
  })
}

// 修改任务奖励用户记录
export function updateTaskUser(data) {
  return request({
    url: '/user/task-users/edit',
    method: 'post',
    data: data
  })
}

// 删除任务奖励用户记录
export function delTaskUser(data) {
  return request({
    url: '/user/task-users/delete',
    method: 'post',
    data: data
  })
}
